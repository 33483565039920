import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faEnvelope,
} from '@fortawesome/free-regular-svg-icons'

import {
  faTwitter,
  faCodepen,
  faLinkedin,
  faGithub,
  faInstagram,
  faDribbble,
  faWordpress,
} from '@fortawesome/free-brands-svg-icons'

import Layout from "../components/layoutComingSoon"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

// images

const sgd_logo = "../images/SG_logo_textSymbol.png"
const wp_logo = "../images/wordpress-logo-hoz-w.png"
const shopify_logo = "../images/shopify_white.png"
const squarespace_logo = "../images/squarespace.png"

const services = [ 
  {
    url:"https://www.sgd-design.com/",
    image:<StaticImage src={wp_logo} loading="eager" width={179} alt="WordPress" style={{opacity: 0.75, }} />,
    title:"WordPress"
  },
  {
    url:"https://www.shopify.com/?ref=sgd-design",
    image:<StaticImage src={shopify_logo} loading="eager" width={179} alt="Shopify" style={{opacity: 0.75, }} />,
    title:"Shopify"
  },
  {
    url:"https://www.sgd-design.com/",
    image:<StaticImage src={squarespace_logo} loading="eager" width={179} alt="SquareSpace" style={{opacity: 0.75, }} />,
    title:"Squarespace"
  }
]
const socials = [
  {
    title:"Email",
    url:"mailto:info@sgd-design.com",
    icon: <FontAwesomeIcon icon={faEnvelope} size="2x"/>
  },
  {
    title:"Twitter",
    url:"http://twitter.com/sgddesign",
    icon: <FontAwesomeIcon icon={faTwitter} size="2x" />
  },
  {
    title:"faCodepen",
    url:"http://codepen.io/SGD-DESIGN/",
    icon: <FontAwesomeIcon icon={faCodepen} size="2x" />
  },
  {
    title:"Linkedin",
    url:"https://www.linkedin.com/in/calvin-harris-87824414",
    icon: <FontAwesomeIcon icon={faLinkedin} size="2x" />
  },
  {
    title:"Github",
    url:"https://github.com/SGD-DESIGN",
    icon: <FontAwesomeIcon icon={faGithub} size="2x" />
  },
  {
    title:"Instagram",
    url:"https://www.instagram.com/sgddesign/",
    icon: <FontAwesomeIcon icon={faInstagram} size="2x" />
  },
  {
    title:"Dribbble",
    url:"https://www.dribbble.com/sgddesign/",
    icon: <FontAwesomeIcon icon={faDribbble} size="2x" />
  },
  {
    title:"Wordpress",
    url:"http://www.stompingroundigital.com/",
    icon: <FontAwesomeIcon icon={faWordpress} size="2x" />
  }
]

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="comingsoon-title">
          <h2>
            <StaticImage src={sgd_logo} loading="eager"width={256}  alt="StompinGrounDigital | Design" style={{opacity: 0.75, }}/>
        </h2>
    </div>

    <hr/>

    <ul className="services list-inline">
        {services.map(service => (
          <li key={service.url} className={styles.listItem}>
            <a
              className={styles.listItemsLink}
              href={`${service.url}${utmParameters}`}
            >
            {service.image} 
            </a>
          </li>
        ))}
    </ul>

    <hr/>

    <ul className="socials list-inline">
      
      {socials.map(social => (
          <>
            <li key={social.indexOf} className={social.classes}>
            
              <a
                className={styles.listItemsLink}
                href={`${social.url}`}
              >
                {social.icon}
              </a>
            </li>
            <li className="sep"/>
          </>
        ))}
    </ul>

 

  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
