/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

// import { StaticImage } from "gatsby-plugin-image"

import GbiBridged from "./backgroundImage"

import "./layoutComingSoon.css"

// 
// const backgroundImage = "../images/IMG_3062.jpg"

const LayoutComingSoon = ({ children }) => {
  

  return (
    <>
    <div className="home">
      <section id="comingsoon" className="section">
        <div className="container">
          <div className="row row-centered">
            <div className="col-lg-6 col-centered">
              {children}
            </div>
          </div>
        </div>
      </section>
    </div>
    <div style={{ display: 'grid',position:'relative' }}>
      <div id="bg-overlay" style={{ gridArea: "1/1",
          position: "fixed",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid"}}/>
            {/* <StaticImage 
            id="bg" src={backgroundImage} 
            layout="fullWidth" 
            aspectRatio={16 / 9} 
            alt=""
            formats={["auto", "webp", "avif"]}
            style={{
              backgroundImage: 'radial-gradient(circle at center,#5dc1ff,#001a2a)',
              backgroundBlendMode: 'overlay',
              backgroundSize: 'cover',
              backgroundAttachment: 'fixed',
              backgroundPosition: '50%',
              filter: 'blur(0) grayscale(100%)',
              zIndex: -2,
              opacity: 1,
              gridArea: '1/1',
              display: "grid"
              }}/> */}
        
    </div>
      <GbiBridged/>
    </>
  )
}

LayoutComingSoon.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutComingSoon
