import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
// import {  GatsbyImage } from "gatsby-plugin-image"

import { BgImage } from "gbimage-bridge"
// import { convertToBgImage } from "gbimage-bridge"
// import BackgroundImage from 'gatsby-background-image'

const GbiBridged = () => {
    const { theRock } = useStaticQuery(
      graphql`
        query {
          theRock: file(relativePath: { eq: "IMG_3062.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 2000,
                quality:50,
                placeholder: BLURRED
                webpOptions: {quality:90}
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      `
    )

   const pluginImage = getImage(theRock)
  
    return (
    //   <BackgroundImage
    //     Tag="section"
    //     // Spread bgImage into BackgroundImage:
    //     {...bgImage}
    //     preserveStackingContext
    //   >
    //     <div id="bg-image" style={{minHeight: 1000, minWidth: 1000}}>
    //       {/* <GatsbyImage image={image} alt={"testimage"}/> */}
    //     </div>
    //   </BackgroundImage>
    <BgImage image={pluginImage} className="bg-image"/>
    )
  }
  export default GbiBridged